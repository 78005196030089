import { isNumber } from '../is'

/**
 * 校验是否是手机号
 * @param phone
 * @returns
 */
export function checkPhone(phone: number | string) {
  if (isNumber(phone))
    phone = phone.toString()
  return /^1[3-9]\d{9}$/.test(phone)
}
/**
 * 校验邮箱是否正确
 * @param phone
 * @returns
 */
export function checkEmail(email: string) {
  return typeof email === 'string' && !!email.match(/^\S+?@\S+?\.\S+?$/) && email.length < 255
}
export function checkQQ(qq: string) {
  return typeof qq === 'string' && qq.match(/[1-9][0-9]{4,}$/) && qq.length < 20
}

export default {
  checkPhone,
  checkEmail,
  checkQQ,
}
